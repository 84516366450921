import logo from './logo.svg';
import './App.css';
import App_1 from './App_1.module.scss';

import img from './seasonal-holidays-2022-6753651837109831.4-law.gif';

function App() {
  return (
	<div className={App_1.wrapper}>
	    <div className="App">
			<div className={App_1.h1}>title</div>
	      <header className="App-header">
	        <img src={process.env.PUBLIC_URL} className="App-logo" alt="logo" />
	        <img src={logo} className="App-logo" alt="logo" />
	        <img src={img} className="" alt="google" />
	        <img src="https://chrome-cors-testing.s3.eu-central-1.amazonaws.com/hacksoft.svg" className="" alt="a" />
	        <p>
	          Edit <code>src/App.js</code> and save to reload.
	        </p>
	        <a
	          className="App-link"
	          href="https://reactjs.org"
	          target="_blank"
	          rel="noopener noreferrer"
	        >
	          Learn React
	        </a>
	      </header>
	    </div>
	</div>
  );
}

export default App;
